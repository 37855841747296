<template>
  <div class="top_banner" :style="bannerStyle">
    <span class="bdg mr-2" :style="badgeStyle">{{ badgeText }}</span>
    <span v-html="text"></span>
    <a :style="hrefStyle" target="_blank" :href="hrefLink">{{ hrefText }}</a>
    <span class="ml-2">{{ emoji }}</span>
    <i type="button" @click="hideTopBanner" :style="iconStyle" class="far fa-times"></i>
  </div>
</template>
<script>
export default {
  name: 'UiTopBanner',
  props: {
    text: {
      type: String,
      default: ''
    },
    emoji: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: '#ffffff'
    },
    bgColor: {
      type: String,
      default: '#3988fe'
    },
    borderColor: {
      type: String,
      default: 'transparent'
    },
    fontSize: {
      type: String,
      default: '0.9rem'
    },
    hrefText: {
      type: String,
      default: ''
    },
    hrefColor: {
      type: String,
      default: '#ffffff'
    },
    hrefFontSize: {
      type: String,
      default: '0.9rem'
    },
    hrefLink: {
      type: String,
      default: ''
    },
    hrefHoverColor: {
      type: String,
      default: '#007bff'
    },
    iconColor: {
      type: String,
      default: '#ffffff'
    },
    type: {
      type: String,
      default: ''
    },
    badgeBgColor: {
      type: String,
      default: '#28a745'
    },
    badgeTextColor: {
      type: String,
      default: '#fff'
    },
    badgeRadius: {
      type: String,
      default: '4px'
    },
    badgeFontSize: {
      type: String,
      default: '0.7rem'
    },
    badgeText: {
      type: String,
      default: ''
    }
  },
  computed: {
    bannerStyle () {
      return {
        '--background': this.bgColor,
        '--color': this.color,
        '--border': '1px solid' + this.borderColor,
        '--font-size': this.fontSize
      }
    },
    hrefStyle () {
      return {
        '--hrefColor': this.hrefColor,
        '--hrefFontSize': this.hrefFontSize,
        '--hrefHoverColor': this.hrefHoverColor
      }
    },
    iconStyle () {
      return {
        '--iconColor': this.iconColor
      }
    },
    badgeStyle () {
      return {
        '--badgeBgColor': this.badgeBgColor,
        '--badgeTextColor': this.badgeTextColor,
        '--badgeRadius': this.badgeRadius,
        '--badgeFontSize': this.badgeFontSize
      }
    }
  },
  created () {
  },
  methods: {
    hideTopBanner () {
      this.$parent.hideTopBanner(this.type)
    }
  }
}
</script>
<style lang="less" scoped>
.top_banner {
  background: var(--background);
  border: var(--border);
  font-size: var(--font-size);
  padding: 19px;
  text-align: center;

  span {
    color: inherit;
    display: inline-block;
    vertical-align: middle;
    color: var(--color);
  }

  .bdg {
    color: var(--badgeTextColor);
    background: var(--badgeBgColor);
    border-radius: var(--badgeRadius);
    font-size: var(--badgeFontSize);
    padding: 0px 3px;
  }

  a {
    color: var(--hrefColor);
    font-size: var(--hrefFontSize);
    display: inline-block;
    font-weight: bold;
    margin-left: 5px;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: var(--hrefHoverColor)
    }
  }

  i {
    color: var(--iconColor);
    font-weight: bold;
    margin-left: 30px;
    cursor: pointer;
  }
}
</style>
